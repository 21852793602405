import { AppConfig } from '@app/app.config';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var videojs: any;

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  private videos: Object;
  private videosSelected: Object;
  private showLoader: Boolean = false;

  constructor(private httpClient: HttpClient,
    private appConfig: AppConfig,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.httpClient.get(this.appConfig.getConfig('videos')).subscribe(
      (data) => {
        this.videos = data;
      }
    );
  }

  loadVideoSec(video: Object, i: any) {
    if (this.videosSelected && this.videosSelected[i]) {
      return;
    }
    this.showLoader = true;
    this.resetObject(this.videosSelected);
    this.videosSelected = [];
    if (video && video['url']) {
      this.httpClient.get(video['url']).subscribe(
        (data) => {
          this.videosSelected[i] = data || [];
          let lengthVideo = 0;
          for (const obj in this.videosSelected[i]) {
            if (this.videosSelected[i][obj]) {
              lengthVideo++;
            }
          }
          if (lengthVideo) {
            const checkCarousel = setInterval(() => {
              const cards = $('.owl-carousel div.card').length;
              if (cards === lengthVideo) {
                this.loadCarousel();
                clearInterval(checkCarousel);
                this.hideLoader();
              }
            }, 300);
          } else {
            this.resetObject(this.videosSelected);
            this.hideLoader();
          }
        }
      );
    }
  }

  loadVideo(videoEl: any) {
    videojs(videoEl);
  }

  resetObject(obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        delete obj[prop];
      }
    }
  }

  loadCarousel() {
    $('.owl-carousel').owlCarousel({
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        },
        1300: {
          items: 4
        },
        1700: {
          items: 5
        }
      }
    });
  }

  hideLoader() {
    this.showLoader = false;
  }

}
