import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {

    private config: Object = null;

    constructor(private httpClient: HttpClient) { }

    public getConfig(key: any) {
        return this.config[key];
    }

    public load() {
        return new Promise((resolve, reject) => {
            this.httpClient.get(environment.urlMainConfig).subscribe((responseData) => {
                this.config = responseData;
                resolve(true);
            });
        });
    }
}
