import { Component, OnInit } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  photos: Object;

  constructor(private httpClient: HttpClient,
    private appConfig: AppConfig) { }

  ngOnInit() {
    this.httpClient.get(this.appConfig.getConfig('photos')).subscribe(
      (data) => {
        this.photos = data;
      }
    );
  }

}
